import { createSlice } from "@reduxjs/toolkit";

import getUnique from "../components/common/getUnique";

let initialState = [];

if (localStorage.getItem("orders")) {
  initialState = JSON.parse(localStorage.getItem("orders"));
}

export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    storeOrders: (state, action) => {
      const uniqueOrders = getUnique(state, action.payload);
      localStorage.setItem("orders", JSON.stringify(uniqueOrders));
      return uniqueOrders;
    },
    updateStoreOrders: (state, action) => {
      localStorage.setItem("orders", JSON.stringify(action.payload));
      return action.payload;
    },
    removeStoreOrders: (state, action) => {
      return action.payload;
    },
  },
});

export const { storeOrders, updateStoreOrders, removeStoreOrders } =
  ordersSlice.actions;

export default ordersSlice.reducer;
