import React from "react";
import { useSelector } from "react-redux";

import { getAllAddiv3, getAllMyAddiv3 } from "../../../functions/address";

const Addiv2Select = ({
  address,
  setAddress,
  countries,
  addiv1s,
  addiv2s,
  setAddiv3s,
  loading,
  setLoading,
}) => {
  const estoreSet = useSelector((state) => state.estoreSet);

  const handleMyAddiv2Change = (e) => {
    const addiv2 = addiv2s.find((addiv2) => addiv2._id === e.target.value);
    const addiv1 = addiv1s.find((addiv1) => addiv1._id === addiv2.adDivId1);
    const country = countries.find((country) => country._id === addiv1.couid);

    if (addiv2) {
      setAddress({
        ...address,
        addiv2: addiv2,
        addiv3: {},
      });
      setLoading(true);
      if (estoreSet && estoreSet.locationType === "anywhere") {
        getAllAddiv3(country._id, addiv1._id, addiv2._id).then((res) => {
          setAddiv3s(res.data);
          setLoading(false);
        });
      } else {
        getAllMyAddiv3(estoreSet._id, country._id, addiv1._id, addiv2._id).then(
          (res) => {
            setAddiv3s(res.data);
            setLoading(false);
          }
        );
      }
    }
  };

  return (
    <select
      name="addiv2"
      className="form-control"
      onChange={handleMyAddiv2Change}
      value={address.addiv2 && address.addiv2._id ? address.addiv2._id : ""}
      disabled={loading}
    >
      <option value="" disabled hidden>
        - choose -
      </option>
      {addiv2s.map((addiv2) => (
        <option key={addiv2._id} value={addiv2._id}>
          {addiv2.name}
        </option>
      ))}
    </select>
  );
};

export default Addiv2Select;
