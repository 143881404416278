import React from "react";
import { useSelector } from "react-redux";
import { PoweroffOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { isMobile } from "react-device-detect";

const NoStore = () => {
  const estoreSet = useSelector((state) => state.estoreSet);

  return (
    <>
      <div className="not-found-container" style={{ marginTop: -80 }}>
        <div className="not-found-content" style={{ borderRadius: 12 }}>
          <h1
            style={{
              fontSize: "3rem",
              color: "#333",
              marginBottom: "1rem",
            }}
          >
            {estoreSet && estoreSet.slug ? "Welcome!" : "Oops!"}
          </h1>
          <p style={{ fontSize: "1.5rem", color: "#777" }}>
            {estoreSet && estoreSet._id ? (
              <Button
                type="primary"
                icon={<PoweroffOutlined />}
                size="large"
                onClick={() => (window.location.href = "/" + estoreSet.slug)}
                style={{
                  borderRadius: 6,
                  fontSize: isMobile ? 14 : 18,
                  height: 50,
                }}
              >
                Proceed to {estoreSet && estoreSet.name} NOW
              </Button>
            ) : (
              <>
                This store may not exist.
                <br />
                Please check your Website Link well.
              </>
            )}
          </p>
        </div>
      </div>
    </>
  );
};

export default NoStore;
