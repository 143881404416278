import { createSlice } from "@reduxjs/toolkit";

import getUnique from "../components/common/getUnique";

let initialState = [];

if (localStorage.getItem("products")) {
  initialState = JSON.parse(localStorage.getItem("products"));
}

export const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    storeProducts: (state, action) => {
      const uniqueProducts = getUnique(state, action.payload);
      localStorage.setItem("products", JSON.stringify(uniqueProducts));
      return uniqueProducts;
    },
    updateStoreProducts: (state, action) => {
      localStorage.setItem("products", JSON.stringify(action.payload));
      return action.payload;
    },
    removeStoreProducts: (state, action) => {
      return action.payload;
    },
  },
});

export const { storeProducts, updateStoreProducts, removeStoreProducts } =
  productSlice.actions;

export default productSlice.reducer;
