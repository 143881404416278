import { createSlice } from "@reduxjs/toolkit";

import getUnique from "../components/common/getUnique";

let initialState = [];

if (localStorage.getItem("users")) {
  initialState = JSON.parse(localStorage.getItem("users"));
}

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    storeUsers: (state, action) => {
      return getUnique(state, action.payload);
    },
    removeStoreUsers: (state, action) => {
      return action.payload;
    },
  },
});

export const { storeUsers, removeStoreUsers } = usersSlice.actions;

export default usersSlice.reducer;
