import { createSlice } from "@reduxjs/toolkit";

import getUnique from "../components/common/getUnique";

let initialState = [];

if (localStorage.getItem("brands")) {
  initialState = JSON.parse(localStorage.getItem("brands"));
}

export const brandSlice = createSlice({
  name: "brands",
  initialState,
  reducers: {
    storeBrands: (state, action) => {
      return getUnique(state, action.payload);
    },
    addStoreBrand: (state, action) => {
      return [...state, action.payload];
    },
    updateStoreBrand: (state, action) => {
      return action.payload;
    },
    removeStoreBrand: (state, action) => {
      return action.payload;
    },
  },
});

export const {
  storeBrands,
  addStoreBrand,
  updateStoreBrand,
  removeStoreBrand,
} = brandSlice.actions;

export default brandSlice.reducer;
