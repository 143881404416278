import axios from "axios";

export const getUserOrder = async (estoreid, orderid, authToken) =>
  await axios.get(process.env.REACT_APP_API + "/gratis/user-order/" + orderid, {
    headers: {
      authToken,
      estoreid,
    },
  });

export const getAdminOrder = async (estoreid, orderid, authToken) =>
  await axios.get(
    process.env.REACT_APP_API + "/gratis/admin-order/" + orderid,
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );

export const getUserOrders = async (
  estoreid,
  authToken,
  sortkey,
  sort,
  currentPage,
  pageSize,
  searchQuery
) =>
  await axios.post(
    process.env.REACT_APP_API + "/gratis/user-orders",
    {
      sortkey,
      sort,
      currentPage,
      pageSize,
      searchQuery,
    },
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );

export const getAdminOrders = async (
  estoreid,
  authToken,
  sortkey,
  sort,
  currentPage,
  pageSize,
  searchQuery,
  status,
  orderedBy
) =>
  await axios.post(
    process.env.REACT_APP_API + "/gratis/admin-orders",
    {
      sortkey,
      sort,
      currentPage,
      pageSize,
      searchQuery,
      status,
      orderedBy,
    },
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );

export const updateCart = async (estoreid, cart, authToken) =>
  await axios.post(
    process.env.REACT_APP_API + "/gratis/update-cart",
    { cart },
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );

export const saveCartOrder = async (estoreid, values, authToken) =>
  await axios.post(
    process.env.REACT_APP_API + "/gratis/save-cart-order",
    values,
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );

export const getAdminSales = async (estoreid, dates, authToken) =>
  await axios.post(
    process.env.REACT_APP_API + "/gratis/admin-sales",
    { dates },
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );

export const changeOrderStatus = async (estoreid, values, authToken) =>
  await axios.put(
    process.env.REACT_APP_API + "/gratis/update-order-status",
    values,
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );

export const updateProductRating = async (estoreid, values, authToken) =>
  await axios.put(
    process.env.REACT_APP_API + "/gratis/update-product-rating",
    values,
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );

export const voidProducts = async (estoreid, values, authToken) =>
  await axios.put(process.env.REACT_APP_API + "/gratis/void-product", values, {
    headers: {
      authToken,
      estoreid,
    },
  });

export const editOrder = async (estoreid, orderid, authToken) =>
  await axios.put(
    process.env.REACT_APP_API + "/gratis/edit-order",
    { orderid },
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );

export const submitEditOrder = async (estoreid, values, authToken) =>
  await axios.put(
    process.env.REACT_APP_API + "/gratis/submit-edit-order",
    values,
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );

export const deleteAdminOrder = async (estoreid, orderid, authToken) =>
  await axios.delete(
    process.env.REACT_APP_API + "/gratis/delete-admin-order/" + orderid,
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );

export const deleteOrder = async (estoreid, orderid, authToken) =>
  await axios.delete(
    process.env.REACT_APP_API + "/gratis/delete-order/" + orderid,
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );
