import React, { useState, useEffect } from "react";
import { BrowserView } from "react-device-detect";
import { Link } from "react-router-dom";
import { Badge } from "antd";
import {
  HomeOutlined,
  LoginOutlined,
  UserOutlined,
  UserAddOutlined,
  ShoppingOutlined,
  ShoppingCartOutlined,
  BarcodeOutlined,
  GlobalOutlined,
  MergeOutlined,
  PhoneOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";

import SearchHead from "./SearchHead";
import Categories from "./Categories";
import Barcode from "../modal/Barcode";
import UpgradeVerify from "../modal/UpgradeVerify";

const sections = [
  { name: "Barcode Menu", value: "barcode", show: false },
  { name: "Stores Menu", value: "stores", show: false },
  { name: "Contact Us", value: "contact", show: false },
  { name: "About Us", value: "about", show: false },
];

const Header = ({ setLocModalVisible }) => {
  const cart = useSelector((state) => state.cart);
  const user = useSelector((state) => state.user);
  const estoreSet = useSelector((state) => state.estoreSet);

  const [isBarcodeOpen, setIsBarcodeOpen] = useState(false);
  const [scanCount, setScanCount] = useState(0);
  const [isUpVerOpen, setIsUpVerOpen] = useState(false);
  const [showList, setShowList] = useState([]);

  useEffect(() => {
    if (user.role === "admin") {
      setShowList(
        sections.map((sec) => {
          return { ...sec, show: true };
        })
      );
    } else if (user.role === "moderator") {
      setShowList(
        sections.map((sec) => {
          return {
            ...sec,
            show:
              estoreSet &&
              estoreSet.accessibility &&
              estoreSet.accessibility.moderator.includes(sec.value),
          };
        })
      );
    } else if (user.role === "cashier") {
      setShowList(
        sections.map((sec) => {
          return {
            ...sec,
            show:
              estoreSet &&
              estoreSet.accessibility &&
              estoreSet.accessibility.cashier.includes(sec.value),
          };
        })
      );
    } else {
      setShowList(
        sections.map((sec) => {
          return {
            ...sec,
            show:
              estoreSet &&
              estoreSet.accessibility &&
              estoreSet.accessibility.customer.includes(sec.value),
          };
        })
      );
    }
  }, [user, estoreSet]); // eslint-disable-line react-hooks/exhaustive-deps

  const menuStyle = {
    mainContainer: {
      backgroundColor:
        estoreSet && estoreSet.headerColor ? estoreSet.headerColor : "#009A57",
      height: "56px",
      position: "fixed",
      zIndex: 2,
      width: "100%",
      top: 0,
    },
    headerStyle: {
      color: "#ffffff",
      borderBottom: `1px solid ${
        estoreSet && estoreSet.headerColor ? estoreSet.headerColor : "#009A57"
      }`,
    },
  };

  return (
    <div
      className="d-flex justify-content-between"
      style={menuStyle.mainContainer}
    >
      <BrowserView className="d-flex flex-row">
        {estoreSet && estoreSet._id && <Categories />}
        <div className="p-3 mr-2">
          <Link
            to={`/${estoreSet.slug ? estoreSet.slug : ""}`}
            style={menuStyle.headerStyle}
          >
            <HomeOutlined /> Home
          </Link>
        </div>
        {estoreSet && estoreSet._id && (
          <div className="p-3 mr-2">
            <Link to={`/${estoreSet.slug}/shop`} style={menuStyle.headerStyle}>
              <ShoppingOutlined /> Shop
            </Link>
          </div>
        )}
        {estoreSet && estoreSet.locationType === "specific" && (
          <div className="p-3 mr-2">
            <Link
              onClick={() => setLocModalVisible(true)}
              style={menuStyle.headerStyle}
            >
              <GlobalOutlined /> Location
            </Link>
          </div>
        )}
        {estoreSet && estoreSet._id && (
          <div className="p-3 mr-2">
            <Link to={`/${estoreSet.slug}/cart`} style={menuStyle.headerStyle}>
              <ShoppingCartOutlined />{" "}
              <Badge
                count={
                  cart &&
                  cart
                    .map((p) => parseInt(p.count))
                    .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                }
                offset={[11, 0]}
              >
                <span style={menuStyle.headerStyle}>Cart</span>
              </Badge>
            </Link>
          </div>
        )}
        {showList.find((list) => list.value === "barcode" && list.show) &&
          user &&
          ["admin", "moderator", "cashier"].includes(user.role) && (
            <div className="p-3 mr-2">
              <div
                onClick={() => {
                  estoreSet &&
                  (!estoreSet.upStatus || estoreSet.upStatus === "Pending")
                    ? setIsUpVerOpen(true)
                    : setIsBarcodeOpen(true);
                }}
                style={{ ...menuStyle.headerStyle, cursor: "pointer" }}
              >
                <BarcodeOutlined /> Barcode
              </div>
            </div>
          )}
        {showList.find((list) => list.value === "stores" && list.show) && (
          <div className="p-3 mr-2">
            <Link to={`/stores`} style={menuStyle.headerStyle}>
              <MergeOutlined />{" "}
              <span style={menuStyle.headerStyle}>Stores</span>
            </Link>
          </div>
        )}
        {showList.find((list) => list.value === "contact" && list.show) && (
          <div className="p-3 mr-2">
            <Link to={`/contact`} style={menuStyle.headerStyle}>
              <PhoneOutlined />{" "}
              <span style={menuStyle.headerStyle}>Contact Us</span>
            </Link>
          </div>
        )}
        {showList.find((list) => list.value === "about" && list.show) && (
          <div className="p-3 mr-2">
            <Link to={`/about`} style={menuStyle.headerStyle}>
              <TeamOutlined />{" "}
              <span style={menuStyle.headerStyle}>About Us</span>
            </Link>
          </div>
        )}
      </BrowserView>
      <div className="d-flex justify-content-end">
        {isMobile && estoreSet && estoreSet._id && <Categories />}
        <div className={isMobile ? "" : "mr-2"} style={{ padding: "13px" }}>
          <SearchHead />
        </div>

        {isMobile && estoreSet && estoreSet.locationType === "specific" && (
          <Link
            onClick={() => setLocModalVisible(true)}
            style={{ color: "#ffffff", fontSize: 24, marginTop: 10 }}
          >
            <GlobalOutlined />
          </Link>
        )}

        {user.token && (
          <>
            <BrowserView className="p-3 mr-2">
              <Link
                to={
                  user && user.role === "admin"
                    ? `/${estoreSet.slug}/admin/dashboard`
                    : user && ["moderator", "cashier"].includes(user.role)
                    ? `/${estoreSet.slug}/admin/orders`
                    : user &&
                      user.role === "customer" &&
                      `/${estoreSet.slug}/user/order`
                }
                style={menuStyle.headerStyle}
              >
                <UserOutlined />{" "}
                {user.name || (user.email && user.email.split("@")[0])}
              </Link>
            </BrowserView>
          </>
        )}

        {!user.token && (
          <>
            <BrowserView className="p-3 mr-2">
              <Link
                to={`/${estoreSet.slug}/auth`}
                style={menuStyle.headerStyle}
              >
                <LoginOutlined /> Login
              </Link>
            </BrowserView>
            <BrowserView className="p-3 mr-2">
              <Link
                to={`/${estoreSet.slug}/auth/?register=1`}
                style={menuStyle.headerStyle}
              >
                <UserAddOutlined /> Register
              </Link>
            </BrowserView>
          </>
        )}
      </div>
      <Barcode
        isBarcodeOpen={isBarcodeOpen}
        setIsBarcodeOpen={setIsBarcodeOpen}
        purpose="read"
        scanCount={scanCount}
        setScanCount={setScanCount}
      />

      <UpgradeVerify
        isUpVerOpen={isUpVerOpen}
        setIsUpVerOpen={setIsUpVerOpen}
      />
    </div>
  );
};

export default Header;
