import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button } from "antd";
import { toast } from "react-toastify";

import CountrySelect from "./location/CountrySelect";
import Addiv1Select from "./location/Addiv1Select";
import Addiv2Select from "./location/Addiv2Select";
import Addiv3Select from "./location/Addiv3Select";

import { getAllMyCountry } from "../../functions/address";
import { updateUserDetails } from "../../functions/user";
import { emptyCart } from "../../reducers/cartSlice";
import { removeStoreCategory } from "../../reducers/categorySlice";
import { removeStorePayment } from "../../reducers/paymentSlice";
import { removeStoreProducts } from "../../reducers/productSlice";
import { removeStoreUsers } from "../../reducers/usersSlice";
import { removeStoreOrders } from "../../reducers/ordersSlice";
import { storeLocation, removeLocation } from "../../reducers/locationSlice";
import { loginUser } from "../../reducers/userSlice";

const initialAddress = {
  country: {},
  addiv1: {},
  addiv2: {},
  addiv3: {},
};

const LocationModal = ({ locModalVisible, setLocModalVisible }) => {
  let dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const estoreSet = useSelector((state) => state.estoreSet);
  const location = useSelector((state) => state.location);

  const [countries, setCountries] = useState([]);
  const [addiv1s, setAddiv1s] = useState([]);
  const [addiv2s, setAddiv2s] = useState([]);
  const [addiv3s, setAddiv3s] = useState([]);
  const [address, setAddress] = useState(initialAddress);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadAllMyCountry();
  }, [estoreSet]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadAllMyCountry = () => {
    const countries = location && location.countries;
    if (countries && countries.length > 0) {
      setCountries(countries);
    } else {
      if (
        estoreSet &&
        estoreSet.locationType &&
        estoreSet.locationType === "specific"
      ) {
        getAllMyCountry(estoreSet._id).then((res) => {
          setCountries(res.data);
          dispatch(storeLocation({ countries: res.data }));
        });
      }
    }
  };

  const handleModal = async () => {
    if (address.addiv3 && address.addiv3._id) {
      dispatch(emptyCart());
      dispatch(removeStoreCategory([]));
      dispatch(removeStorePayment([]));
      dispatch(removeStoreProducts([]));
      dispatch(removeStoreUsers([]));
      dispatch(removeStoreOrders([]));
      if (user._id) {
        await updateUserDetails(
          estoreSet._id,
          { ...user, address },
          user.token
        );
      } else {
        sessionStorage.setItem("address", JSON.stringify(address));
        sessionStorage.setItem("popUpLocation", false);
      }
      window.location.reload();
    } else {
      toast.error("No location have entered");
    }
  };

  const resetLocation = () => {
    if (user && user.token) {
      updateUserDetails(
        estoreSet._id,
        { ...user, address: {} },
        user.token
      ).then((res) => {
        dispatch(
          loginUser({
            address: {},
          })
        );
        dispatch(removeLocation({}));
        localStorage.removeItem("location");
        window.location.reload();
      });
    } else {
      dispatch(
        loginUser({
          address: {},
        })
      );
      dispatch(removeLocation({}));
      localStorage.removeItem("location");
      window.location.reload();
    }
  };

  return (
    <>
      <Modal
        title="Place your location so we may know what products can be delivered to you"
        centered
        open={locModalVisible}
        onCancel={() => {
          setLocModalVisible(false);
          sessionStorage.setItem("popUpLocation", true);
        }}
        footer={[
          <Button
            key="cancel"
            type="default"
            onClick={() => {
              setLocModalVisible(false);
              sessionStorage.setItem("popUpLocation", true);
            }}
          >
            Cancel
          </Button>,
          <Button key="reset" type="primary" danger onClick={resetLocation}>
            Reset
          </Button>,
          <Button key="submit" type="primary" onClick={handleModal}>
            Submit
          </Button>,
        ]}
        style={{ borderRadius: 12, overflow: "hidden" }}
      >
        <div className="form-group">
          <label>
            <b>Country</b>
          </label>
          <CountrySelect
            address={address}
            setAddress={setAddress}
            countries={countries}
            setAddiv1s={setAddiv1s}
            setAddiv2s={setAddiv2s}
            setAddiv3s={setAddiv3s}
            loading={loading}
            setLoading={setLoading}
          />
        </div>
        {estoreSet.country && estoreSet.country.adDivName1 && (
          <div className="form-group">
            <label>
              <b>{estoreSet.country.adDivName1}</b>
            </label>
            <Addiv1Select
              address={address}
              setAddress={setAddress}
              countries={countries}
              addiv1s={addiv1s}
              setAddiv2s={setAddiv2s}
              loading={loading}
              setLoading={setLoading}
            />
          </div>
        )}
        {estoreSet.country && estoreSet.country.adDivName1 && (
          <div className="form-group">
            <label>
              <b>{estoreSet.country.adDivName2}</b>
            </label>
            <Addiv2Select
              address={address}
              setAddress={setAddress}
              countries={countries}
              addiv1s={addiv1s}
              addiv2s={addiv2s}
              setAddiv3s={setAddiv3s}
              loading={loading}
              setLoading={setLoading}
            />
          </div>
        )}
        {estoreSet.country && estoreSet.country.adDivName1 && (
          <div className="form-group">
            <label>
              <b>{estoreSet.country.adDivName3}</b>
            </label>
            <Addiv3Select
              address={address}
              setAddress={setAddress}
              addiv3s={addiv3s}
              loading={loading}
            />
          </div>
        )}
      </Modal>
    </>
  );
};

export default LocationModal;
