import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import NoStore from "../../pages/NoStore";

const NoUserRoute = ({ children, setSlug, estore }) => {
  const navigate = useNavigate();
  const { slug } = useParams();

  const user = useSelector((state) => state.user);
  const estoreSet = useSelector((state) => state.estoreSet);

  useEffect(() => {
    if (slug) {
      setSlug(slug);
    }
    if (
      user &&
      user.token &&
      estoreSet &&
      (!estoreSet.upStatus || estoreSet.upStatus === "Pending")
    ) {
      navigate(`/${estoreSet.slug}/admin/payupgrade/1`);
    }
  }, [slug]); // eslint-disable-line react-hooks/exhaustive-deps

  return estore && estore._id ? <>{children}</> : <NoStore />;
};

export default NoUserRoute;
