import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  getAllAddiv1,
  getAllMyAddiv1,
  getAllAddiv2,
  getAllMyAddiv2,
  getAllAddiv3,
  getAllMyAddiv3,
} from "../../../functions/address";
import { storeLocation } from "../../../reducers/locationSlice";

const CountrySelect = ({
  address,
  setAddress,
  countries,
  setAddiv1s,
  setAddiv2s,
  setAddiv3s,
  loading,
  setLoading,
}) => {
  let dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const estoreSet = useSelector((state) => state.estoreSet);
  const location = useSelector((state) => state.location);

  useEffect(() => {
    if (
      user &&
      user.address &&
      user.address.country &&
      user.address.country._id &&
      user.address.addiv1 &&
      user.address.addiv1._id &&
      user.address.addiv2 &&
      user.address.addiv2._id &&
      user.address.addiv3 &&
      user.address.addiv3._id
    ) {
      loadAllAddress();
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadAllAddress = () => {
    const country =
      user && user.address && user.address.country ? user.address.country : {};
    const addiv1 =
      user && user.address && user.address.country ? user.address.addiv1 : {};
    const addiv2 =
      user && user.address && user.address.country ? user.address.addiv2 : {};
    const addiv3 =
      user && user.address && user.address.country ? user.address.addiv3 : {};
    const details =
      user && user.address && user.address.country ? user.address.details : "";

    const addiv1s =
      location.addiv1s &&
      location.addiv1s.filter((addiv1) => addiv1.couid === country._id);

    if (addiv1s && addiv1s.length > 0) {
      setAddiv1s(addiv1s);
    } else {
      if (estoreSet && estoreSet.locationType === "anywhere") {
        getAllAddiv1(country._id).then((res) => {
          setAddiv1s(res.data);
          dispatch(storeLocation({ addiv1s: res.data }));
        });
      } else {
        getAllMyAddiv1(estoreSet._id, country._id).then((res) => {
          setAddiv1s(res.data);
          dispatch(storeLocation({ addiv1s: res.data }));
        });
      }
    }

    const addiv2s =
      location.addiv2s &&
      location.addiv2s.filter((addiv2) => addiv2.adDivId1 === addiv1._id);

    if (addiv2s && addiv2s.length > 0) {
      setAddiv2s(addiv2s);
    } else {
      setLoading(true);
      if (estoreSet && estoreSet.locationType === "anywhere") {
        getAllAddiv2(country._id, addiv1._id).then((res) => {
          setAddiv2s(res.data);
          dispatch(storeLocation({ addiv2s: res.data }));
          setLoading(false);
        });
      } else {
        getAllMyAddiv2(estoreSet._id, country._id, addiv1._id).then((res) => {
          setAddiv2s(res.data);
          dispatch(storeLocation({ addiv2s: res.data }));
          setLoading(false);
        });
      }
    }

    const addiv3s =
      location.addiv3s &&
      location.addiv3s.filter((addiv3) => addiv3.adDivId2 === addiv2._id);

    if (addiv3s && addiv3s.length > 0) {
      const newAddiv3 = addiv3s.find((newAdd) => newAdd._id === addiv3._id);
      setAddress({
        ...address,
        country,
        addiv1,
        addiv2,
        addiv3: newAddiv3,
        details,
      });
      setAddiv3s(addiv3s);
    } else {
      setLoading(true);
      if (estoreSet && estoreSet.locationType === "anywhere") {
        getAllAddiv3(country._id, addiv1._id, addiv2._id).then((res) => {
          const newAddiv3 = res.data.find(
            (newAdd) => newAdd._id === addiv3._id
          );
          setAddress({
            ...address,
            country,
            addiv1,
            addiv2,
            addiv3: newAddiv3,
            details,
          });
          setAddiv3s(res.data);
          dispatch(storeLocation({ addiv3s: res.data }));
          setLoading(false);
        });
      } else {
        getAllMyAddiv3(estoreSet._id, country._id, addiv1._id, addiv2._id).then(
          (res) => {
            const newAddiv3 = res.data.find(
              (newAdd) => newAdd._id === addiv3._id
            );
            setAddress({
              ...address,
              country,
              addiv1,
              addiv2,
              addiv3: newAddiv3,
              details,
            });
            setAddiv3s(res.data);
            dispatch(storeLocation({ addiv3s: res.data }));
            setLoading(false);
          }
        );
      }
    }
  };

  const handleMyCountryChange = (e) => {
    const country =
      countries && countries.find((country) => country._id === e.target.value);

    if (country) {
      setAddress({
        ...address,
        country: country,
        addiv1: {},
        addiv2: {},
        addiv3: {},
      });
      setLoading(true);
      if (estoreSet && estoreSet.locationType === "anywhere") {
        getAllAddiv1(country._id).then((res) => {
          setAddiv1s(res.data);
          setLoading(false);
        });
      } else {
        getAllMyAddiv1(estoreSet._id, country._id).then((res) => {
          setAddiv1s(res.data);
          setLoading(false);
        });
      }
    }
  };

  return (
    <select
      name="country"
      className="form-control"
      onChange={handleMyCountryChange}
      value={address.country && address.country._id ? address.country._id : ""}
      disabled={loading}
    >
      <option value="" disabled hidden>
        - choose -
      </option>
      {countries.map((country) => (
        <option key={country._id} value={country._id}>
          {country.name}
        </option>
      ))}
    </select>
  );
};

export default CountrySelect;
