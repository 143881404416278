import axios from "axios";

export const getAllCountry = async () =>
  await axios.get(process.env.REACT_APP_API + "/gratis/country");

export const getAllAddiv1 = async (couid) =>
  await axios.get(process.env.REACT_APP_API + "/gratis/addiv1/" + couid);

export const getAllAddiv2 = async (couid, addiv1) =>
  await axios.get(
    process.env.REACT_APP_API + "/gratis/addiv2/" + couid + "/" + addiv1
  );

export const getAllAddiv3 = async (couid, addiv1, addiv2) =>
  await axios.get(
    process.env.REACT_APP_API +
      "/gratis/addiv3/" +
      couid +
      "/" +
      addiv1 +
      "/" +
      addiv2
  );

export const copyAllAddiv1 = async (estoreid, country, details, authToken) => {
  await axios.put(
    process.env.REACT_APP_API + "/gratis/copyalladdiv1",
    { country, details },
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );
};

export const saveCreatedLocation1 = async (
  estoreid,
  values,
  details,
  authToken
) => {
  await axios.put(
    process.env.REACT_APP_API + "/gratis/savecreatedlocation1",
    { values, details },
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );
};

export const copyAllAddiv2 = async (
  estoreid,
  country,
  addiv1,
  details,
  authToken
) => {
  await axios.put(
    process.env.REACT_APP_API + "/gratis/copyalladdiv2",
    { country, addiv1, details },
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );
};

export const saveCreatedLocation2 = async (
  estoreid,
  values,
  details,
  authToken
) => {
  await axios.put(
    process.env.REACT_APP_API + "/gratis/savecreatedlocation2",
    { values, details },
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );
};

export const copyAllAddiv3 = async (
  estoreid,
  country,
  addiv1,
  addiv2,
  details,
  authToken
) => {
  await axios.put(
    process.env.REACT_APP_API + "/gratis/copyalladdiv3",
    { country, addiv1, addiv2, details },
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );
};

export const saveCreatedLocation3 = async (
  estoreid,
  values,
  details,
  authToken
) => {
  await axios.put(
    process.env.REACT_APP_API + "/gratis/savecreatedlocation3",
    { values, details },
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );
};

export const saveLocation3 = async (
  estoreid,
  country,
  addiv1,
  addiv2,
  addiv3,
  details,
  authToken
) => {
  await axios.put(
    process.env.REACT_APP_API + "/gratis/savelocation3",
    { country, addiv1, addiv2, addiv3, details },
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );
};

export const getAllMyCountry = async (estoreid) =>
  await axios.get(process.env.REACT_APP_API + "/gratis/mycountry", {
    headers: {
      estoreid,
    },
  });

export const getNewAdded3 = async (estoreid, authToken) =>
  await axios.get(process.env.REACT_APP_API + "/gratis/myaddiv3", {
    headers: {
      authToken,
      estoreid,
    },
  });

export const getAllMyAddiv1 = async (estoreid, couid) =>
  await axios.get(process.env.REACT_APP_API + "/gratis/myaddiv1/" + couid, {
    headers: {
      estoreid,
    },
  });

export const getAllMyAddiv2 = async (estoreid, couid, addiv1) =>
  await axios.get(
    process.env.REACT_APP_API + "/gratis/myaddiv2/" + couid + "/" + addiv1,
    {
      headers: {
        estoreid,
      },
    }
  );

export const getAllMyAddiv3 = async (estoreid, couid, addiv1, addiv2) =>
  await axios.get(
    process.env.REACT_APP_API +
      "/gratis/myaddiv3/" +
      couid +
      "/" +
      addiv1 +
      "/" +
      addiv2,
    {
      headers: {
        estoreid,
      },
    }
  );

export const getAddiv3 = async (estoreid, addiv3) =>
  await axios.get(process.env.REACT_APP_API + "/gratis/locate/" + addiv3, {
    headers: {
      estoreid,
    },
  });

export const updateMyAddiv3 = async (estoreid, addiv3, values, authToken) =>
  await axios.put(
    process.env.REACT_APP_API + "/gratis/updatemyaddiv3/" + addiv3,
    values,
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );

export const deleteAddiv3 = async (estoreid, addiv3Id, authToken) => {
  await axios.delete(
    process.env.REACT_APP_API + "/gratis/deleteaddiv3/?addiv3=" + addiv3Id,
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );
};

export const deleteAddiv2 = async (estoreid, addiv2Id, authToken) => {
  await axios.delete(
    process.env.REACT_APP_API + "/gratis/deleteaddiv2/?addiv2=" + addiv2Id,
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );
};

export const deleteAddiv1 = async (estoreid, addiv1Id, authToken) => {
  await axios.delete(
    process.env.REACT_APP_API + "/gratis/deleteaddiv1/?addiv1=" + addiv1Id,
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );
};
