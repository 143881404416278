import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import usersSlice from "./usersSlice";
import estoreSlice from "./estoreSlice";
import productSlice from "./productSlice";
import cartSlice from "./cartSlice";
import categorySlice from "./categorySlice";
import paymentSlice from "./paymentSlice";
import resellerSlice from "./resellerSlice";
import ordersSlice from "./ordersSlice";
import locationSlice from "./locationSlice";
import brandSlice from "./brandSlice";

export default configureStore({
  reducer: {
    user: userReducer,
    users: usersSlice,
    estoreSet: estoreSlice,
    products: productSlice,
    cart: cartSlice,
    categories: categorySlice,
    payments: paymentSlice,
    resellerSet: resellerSlice,
    orders: ordersSlice,
    location: locationSlice,
    brands: brandSlice,
  },
});
