import React, { useEffect, useState } from "react";
import { Modal, Button, Checkbox, Input, List } from "antd";
import {
  ScanOutlined,
  CameraOutlined,
  CaretLeftOutlined,
  UnorderedListOutlined,
  EnterOutlined,
  ChromeOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BarcodeReader from "react-barcode-reader";
import ImageShow from "../common/ImageShow";
import { Html5QrcodeScanner } from "html5-qrcode";
import { isMobile } from "react-device-detect";

import { getProductByBarcode } from "../../functions/product";
import { updateEstore } from "../../functions/estore";
import { estoreDet } from "../../reducers/estoreSlice";
import { storeToCart } from "../../reducers/cartSlice";
import { updateCart } from "../../functions/order";

const Barcode = ({
  isBarcodeOpen,
  setIsBarcodeOpen,
  purpose,
  setBarcode = () => "",
  scanCount = 0,
  setScanCount = () => "",
  setProducCreate = () => "",
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let html5QrcodeScanner1;
  let html5QrcodeScanner2;
  let html5QrcodeScanner3;

  const products = useSelector((state) => state.products);
  const user = useSelector((state) => state.user);
  const estoreSet = useSelector((state) => state.estoreSet);

  const [scannerUse, setScannerUse] = useState("");
  const [useWebcam, setUseWebcam] = useState(false);
  const [inputBarcode, setInputBarcode] = useState("");
  const [moreProducts, setMoreProducts] = useState([]);

  useEffect(() => {
    if (estoreSet && estoreSet.scannerType && estoreSet.scannerType !== "") {
      setScannerUse(estoreSet.scannerType);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (scannerUse === "webcam" && isBarcodeOpen) {
      loadQrcodeScanner();
    }
  }, [scannerUse, isBarcodeOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadQrcodeScanner = () => {
    if (purpose === "read" || purpose === "write") {
      html5QrcodeScanner1 = new Html5QrcodeScanner(
        "reader1",
        { fps: 10, qrbox: { width: 250, height: 250 } },
        /* verbose= */ false
      );
      html5QrcodeScanner1.render(onScanSuccess1);
    }
    if (purpose === "search") {
      html5QrcodeScanner2 = new Html5QrcodeScanner(
        "reader2",
        { fps: 10, qrbox: { width: 250, height: 250 } },
        /* verbose= */ false
      );
      html5QrcodeScanner2.render(onScanSuccess2);
    }
    if (purpose === "inventory") {
      html5QrcodeScanner3 = new Html5QrcodeScanner(
        "reader3",
        { fps: 10, qrbox: { width: 250, height: 250 } },
        /* verbose= */ false
      );
      html5QrcodeScanner3.render(onScanSuccess3);
    }
  };

  const loadSingleProduct = (barcode) => {
    const singleProduct = products.find(
      (product) => product.barcode === barcode
    );
    if (singleProduct && scanCount === 0) {
      if (purpose === "read") {
        handleAddToCart(singleProduct);
      } else if (purpose === "search") {
        setProducCreate(singleProduct);
      } else if (purpose === "inventory") {
        setProducCreate(singleProduct);
      }
      setIsBarcodeOpen(false);
      setScanCount(1);
    } else {
      getProductByBarcode(purpose, estoreSet._id, barcode).then((res) => {
        if (res.data.err) {
          toast.error(res.data.err);
        } else {
          if (res.data.length > 0) {
            const singleProduct = res.data && res.data[0];
            if (purpose === "read") {
              handleAddToCart(singleProduct);
              setIsBarcodeOpen(false);
              setScanCount(1);
            } else if (purpose === "search") {
              if (res.data.length > 1) {
                setMoreProducts(
                  res.data.map((data) => {
                    return {
                      ...data,
                      images: data.images.map((img) => {
                        return { ...img, fromid: data.estoreid.resellid };
                      }),
                    };
                  })
                );
              } else if (res.data.length === 1) {
                setProducCreate({
                  ...singleProduct,
                  images: singleProduct.images.map((img) => {
                    return { ...img, fromid: singleProduct.estoreid.resellid };
                  }),
                });
                setIsBarcodeOpen(false);
                setScanCount(1);
              }
            } else if (purpose === "inventory") {
              setProducCreate(singleProduct);
              setIsBarcodeOpen(false);
              setScanCount(1);
            }
          } else {
            toast.error("No item found for this barcode.");
            setIsBarcodeOpen(false);
            setScanCount(1);
          }
        }
      });
    }
  };

  const handleAddToCart = (product) => {
    let cart = [];
    if (localStorage.getItem("cart")) {
      cart = JSON.parse(localStorage.getItem("cart"));
    }
    const existProduct = cart.filter((prod) => prod._id === product._id);
    if (existProduct[0]) {
      cart = cart.map((prod) =>
        prod._id === product._id
          ? { ...prod, count: parseFloat(prod.count) + 1 }
          : prod
      );
    } else {
      cart.push({
        ...product,
        count: 1,
      });
    }
    if (user._id && ["admin", "moderator", "cashier"].includes(user.role)) {
      dispatch(storeToCart(cart));
      localStorage.setItem("cart", JSON.stringify(cart));
      updateCart(estoreSet._id, cart, user.token);

      navigate(`/${estoreSet.slug}/cart`);
    }
  };

  const onCheckboxChange = (scannerType) => {
    if (user && ["admin", "moderator", "cashier"].includes(user.role)) {
      const echange =
        estoreSet.estoreChange > 0 ? estoreSet.estoreChange + 1 : 1;

      updateEstore(
        estoreSet._id,
        { ...estoreSet, scannerType, estoreChange: echange },
        user.token
      ).then((res) => {
        if (res.data.err) {
          toast.error(res.data.err);
        } else {
          setScannerUse(scannerType);
          dispatch(estoreDet(res.data));
          localStorage.setItem("estore", JSON.stringify(res.data));
          toast.success(`Default scanner type was successfully updated`);
        }
      });
    } else {
      toast.error(
        "Sorry, you could not modify your setting if your email address is not yet verified."
      );
    }
  };

  const onScanSuccess1 = (decodedText) => {
    if (decodedText) {
      if (purpose === "read") {
        loadSingleProduct(decodedText);
      } else {
        setBarcode(decodedText);
      }
      html5QrcodeScanner1.clear();
    }
  };

  const onScanSuccess2 = (decodedText) => {
    if (decodedText) {
      if (purpose === "search") {
        loadSingleProduct(decodedText);
      } else {
        setBarcode(decodedText);
      }
      html5QrcodeScanner2.clear();
    }
  };

  const onScanSuccess3 = (decodedText) => {
    if (decodedText) {
      if (purpose === "inventory") {
        loadSingleProduct(decodedText);
      } else {
        setBarcode(decodedText);
      }
      html5QrcodeScanner3.clear();
    }
  };

  return (
    <>
      <Modal
        title="Search Item By Barcode"
        open={isBarcodeOpen}
        onCancel={() => {
          setIsBarcodeOpen(false);
          setUseWebcam(false);
          if (
            estoreSet &&
            estoreSet.scannerType &&
            estoreSet.scannerType !== ""
          ) {
            setScannerUse(estoreSet.scannerType);
          } else {
            setScannerUse("");
          }
        }}
        centered
        style={{ borderRadius: 12, overflow: "hidden", textAlign: "center" }}
        footer={null}
        cancelText="Close"
      >
        {moreProducts.length > 1 ? (
          <List
            itemLayout="horizontal"
            dataSource={moreProducts}
            renderItem={(item) => (
              <List.Item
                className="otherProducts"
                onClick={() => {
                  setProducCreate({
                    ...item,
                    images: item.images.map((img) => {
                      return { ...img, fromid: item.estoreid.resellid };
                    }),
                  });
                  setIsBarcodeOpen(false);
                }}
              >
                <List.Item.Meta
                  avatar={
                    <ImageShow
                      alt={item.title}
                      imgid={
                        item.images && item.images.length > 0
                          ? item.images[0].url
                          : ""
                      }
                      fromid={item && item.estoreid && item.estoreid.resellid}
                      style={{
                        width: "100px",
                        height: "100px",
                      }}
                      type="thumb/"
                    />
                  }
                  title={item.title}
                  description={`Price: ${item.price}`}
                  style={{
                    textAlign: "left",
                  }}
                />
              </List.Item>
            )}
          />
        ) : (
          <>
            {scannerUse === "webcam" && (
              <>
                Use your camera to scan the barcode
                <br />
                <br />
                {(purpose === "read" || purpose === "write") && (
                  <div id="reader1" width="600px"></div>
                )}
                {purpose === "search" && <div id="reader2" width="600px"></div>}
                {purpose === "inventory" && (
                  <div id="reader3" width="600px"></div>
                )}
                <br />
                <br />
                <div align="center">
                  <div
                    style={{
                      width: 350,
                      height: 105,
                      marginTop: 5,
                      marginRight: 5,
                      borderRadius: 6,
                      padding: 7,
                      border: "1px dashed #FF7F7F",
                      color: "#FF7F7F",
                      fontSize: 12,
                    }}
                  >
                    NOTE: Scanning barcode using your webcam or celphone's
                    camera may not work properly if you open this in a social
                    media app like FB, Tiktok, Youtube, Instagram, etc. To have
                    this work properly, please have your account open to a web
                    browser such as Google Chrome.{" "}
                    <a
                      href={`/${estoreSet.slug}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Open a browser
                    </a>
                  </div>
                </div>
                {(purpose === "read" ||
                  purpose === "write" ||
                  purpose === "search" ||
                  purpose === "inventory") && (
                  <>
                    <br />
                    <Input
                      prefix={
                        <UnorderedListOutlined className="site-form-item-icon" />
                      }
                      style={{ width: 320 }}
                      placeholder="Type barcode here"
                      onKeyUp={(e) =>
                        (e.code === "Enter" || e.code === "NumpadEnter") &&
                        e.target.value &&
                        loadSingleProduct(e.target.value)
                      }
                      onChange={(e) => setInputBarcode(e.target.value)}
                    />
                    <Button
                      type="primary"
                      size="large"
                      style={{
                        width: 230,
                        borderRadius: 6,
                        margin: "20px 0 0 0",
                      }}
                      onClick={() => {
                        if (inputBarcode) loadSingleProduct(inputBarcode);
                      }}
                    >
                      <EnterOutlined /> Search Barcode
                    </Button>
                  </>
                )}
                <br />
                <Button
                  type="default"
                  size="large"
                  style={{ width: 230, borderRadius: 6, margin: "20px 0" }}
                  onClick={() => setScannerUse("")}
                >
                  <CaretLeftOutlined /> Change Scanner
                </Button>
                <br />
                <Checkbox
                  checked={estoreSet && estoreSet.scannerType === "webcam"}
                  onChange={() => onCheckboxChange("webcam")}
                >
                  Always use your webcam to scan barcode
                </Checkbox>
              </>
            )}
            {scannerUse === "barScan" && (
              <>
                Use your scanner to get the barcode
                <br />
                <BarcodeReader
                  onError={(err) => toast.error(err)}
                  onScan={(data) => {
                    if (data) {
                      if (
                        purpose === "read" ||
                        purpose === "search" ||
                        purpose === "inventory"
                      ) {
                        loadSingleProduct(data);
                      } else {
                        setBarcode(data);
                      }
                    }
                  }}
                />
                <br />
                <ScanOutlined style={{ fontSize: 150 }} />
                {(purpose === "read" ||
                  purpose === "write" ||
                  purpose === "search" ||
                  purpose === "inventory") && (
                  <>
                    <br />
                    <br />
                    <Input
                      prefix={
                        <UnorderedListOutlined className="site-form-item-icon" />
                      }
                      style={{ width: 320 }}
                      placeholder="Type barcode here"
                      onKeyUp={(e) =>
                        (e.code === "Enter" || e.code === "NumpadEnter") &&
                        e.target.value &&
                        loadSingleProduct(e.target.value)
                      }
                      onChange={(e) => setInputBarcode(e.target.value)}
                    />
                    <Button
                      type="primary"
                      size="large"
                      style={{
                        width: 230,
                        borderRadius: 6,
                        margin: "20px 0 0 0",
                      }}
                      onClick={() => {
                        if (inputBarcode) loadSingleProduct(inputBarcode);
                      }}
                    >
                      <EnterOutlined /> Search Barcode
                    </Button>
                  </>
                )}
                <br />
                <Button
                  type="default"
                  size="large"
                  style={{ width: 230, borderRadius: 6, margin: "20px 0" }}
                  onClick={() => setScannerUse("")}
                >
                  <CaretLeftOutlined /> Change Scanner
                </Button>
                <br />
                <Checkbox
                  checked={estoreSet && estoreSet.scannerType === "barScan"}
                  onChange={() => onCheckboxChange("barScan")}
                >
                  Always use scanner to get the barcode
                </Checkbox>
              </>
            )}
            {scannerUse === "" && (
              <>
                {isMobile && useWebcam ? (
                  <>
                    Please note that if you are using your Phone's Camera to
                    scan barcode, you need to open your account in a Web Browser
                    like Google Chrome. Are you in Chrome now or in any type of
                    Web Browser?
                    <br />
                    <br />
                    <Button
                      type="default"
                      size="large"
                      style={{ width: 230, borderRadius: 6 }}
                      onClick={() => setScannerUse("webcam")}
                    >
                      <ChromeOutlined /> Yes, I'm in Chrome now
                    </Button>
                    <br />
                    <Button
                      type="default"
                      size="large"
                      style={{ width: 230, borderRadius: 6, margin: "20px 0" }}
                      onClick={() =>
                        window.open(`/${estoreSet.slug}`, "_blank").focus()
                      }
                    >
                      <GlobalOutlined /> Open a Default Browser
                    </Button>
                  </>
                ) : (
                  <>
                    Please choose the type of scanner to be use.
                    <br />
                    <br />
                    <Button
                      type="default"
                      size="large"
                      style={{ width: 230, borderRadius: 6 }}
                      onClick={() =>
                        isMobile ? setUseWebcam(true) : setScannerUse("webcam")
                      }
                    >
                      <CameraOutlined /> Use Webcam
                    </Button>
                    <br />
                    <Button
                      type="default"
                      size="large"
                      style={{ width: 230, borderRadius: 6, margin: "20px 0" }}
                      onClick={() => setScannerUse("barScan")}
                    >
                      <ScanOutlined /> Use Barcode Scanner
                    </Button>
                  </>
                )}
              </>
            )}
          </>
        )}
      </Modal>
    </>
  );
};

export default Barcode;
