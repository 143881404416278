import { createSlice } from "@reduxjs/toolkit";

let initialState = [];

if (localStorage.getItem("location")) {
  initialState = JSON.parse(localStorage.getItem("location"));
}

export const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    storeLocation: (state, action) => {
      localStorage.setItem(
        "location",
        JSON.stringify({ ...state, ...action.payload })
      );
      return { ...state, ...action.payload };
    },
    removeLocation: (state, action) => {
      return action.payload;
    },
  },
});

export const { storeLocation, removeLocation } = locationSlice.actions;

export default locationSlice.reducer;
