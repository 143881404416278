import { createSlice } from "@reduxjs/toolkit";

let initialState = [];

if (localStorage.getItem("reseller")) {
  initialState = JSON.parse(localStorage.getItem("reseller"));
}

export const resellerSlice = createSlice({
  name: "resellerSet",
  initialState,
  reducers: {
    resellerDet: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { resellerDet } = resellerSlice.actions;

export default resellerSlice.reducer;
