import React from "react";
import { useSelector } from "react-redux";

import { getAllAddiv2, getAllMyAddiv2 } from "../../../functions/address";

const Addiv1Select = ({
  address,
  setAddress,
  countries,
  addiv1s,
  setAddiv2s,
  loading,
  setLoading,
}) => {
  const estoreSet = useSelector((state) => state.estoreSet);

  const handleMyAddiv1Change = (e) => {
    const addiv1 = addiv1s.find((addiv1) => addiv1._id === e.target.value);
    const country = countries.find((country) => country._id === addiv1.couid);

    if (addiv1) {
      setAddress({
        ...address,
        addiv1: addiv1,
        addiv2: {},
        addiv3: {},
      });
      setLoading(true);
      if (estoreSet && estoreSet.locationType === "anywhere") {
        getAllAddiv2(country._id, addiv1._id).then((res) => {
          setAddiv2s(res.data);
          setLoading(false);
        });
      } else {
        getAllMyAddiv2(estoreSet._id, country._id, addiv1._id).then((res) => {
          setAddiv2s(res.data);
          setLoading(false);
        });
      }
    }
  };

  return (
    <select
      name="addiv1"
      className="form-control"
      onChange={handleMyAddiv1Change}
      value={address.addiv1 && address.addiv1._id ? address.addiv1._id : ""}
      disabled={loading}
    >
      <option value="" disabled hidden>
        - choose -
      </option>
      {addiv1s.map((addiv1) => (
        <option key={addiv1._id} value={addiv1._id}>
          {addiv1.name}
        </option>
      ))}
    </select>
  );
};

export default Addiv1Select;
